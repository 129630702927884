:root {
  --main-color: #3f51b5;
  --light-color: #7b1fa237;
  --secondary-color: #7986cb;
  --white-color: #fff;
  --black-color: #000;
  --ff-normal: "Nunito", sans-serif;
  --ff-height: "Oswald", sans-serif;
  --ff-italic: "Pinyon Script", cursive;
  --ff-heading: "Kurale", serif;
}

.meet_us_details {
  height: 100%;
  width: 100%;
  margin-top: -8rem;
  display: flex;
  gap: 3rem;
}
.meet_us_list {
  flex-basis: 25rem;
  min-width: 22rem;
  background: linear-gradient(
    -90deg,
    var(--secondary-color),
    var(--main-color)
  );
  padding-left: 1rem;
  padding-top: 8rem;
  position: relative;
}
.meet_us_list_sticky {
  position: sticky;
  top: 5.5rem;
}
.meet_us_list_name {
  padding: 8px;
  color: var(--white-color);
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  text-transform: capitalize;
}
.meet_us_list_name.active {
  background: var(--secondary-color);
}
.meet_us_des {
  padding-top: 10rem;
  margin-right: 5rem;
  flex-grow: 1;
  margin-bottom: 7rem;
  flex-shrink: 1;
}
.meet_us_des_title {
  font-size: 4.2rem;
  font-family: var(--ff-height);
  line-height: 5rem;
  color: var(--main-color);
}
.meet_us_des_sub_title {
  font-size: 24px;
  font-weight: 700;
  font-family: var(--ff-normal);
  margin: 1rem 0;
  color: var(--secondary-color);
  filter: brightness(0.5);
}
.meet_us_des_details {
  margin-top: 2rem;
  display: flex;
  /* flex-direction: column; */
}
.meet_us_des_details_des {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* justify-content: space-around; */
  font-size: 20px;
  margin: 1rem;
}
.meet_us_des_details_des.satyajit {
  gap: 3rem;
  margin-top: 6rem;
}
.meet_us_des_details_box {
  list-style: disc;
  text-indent: -27px;
  font-size: 20pz;
  padding-left: 25px;
}
.meet_us_des_details_box_img img {
  width: 23rem;
  height: 23rem;
  object-fit: cover;
  background-color: lavender;
  border-radius: 50%;
  /* border: 10px solid var(--secondary-color); */
  /* margin-left: 30px; */
  /* margin-bottom: 15px;*/
  /* vertical-align: middle; */
}
.meet_us_des_details_box_img {
  position: relative;
  float: right;
  margin-left: 2rem;
  margin-bottom: 1rem;
  border-radius: 1rem 1rem 0 0;
  /* border: 1px solid var(--secondary-color); */
  /* box-shadow: rgba(0 0 0/0.2) 0.3rem 0 0.5rem; */
}
.doctor_name {
  /* position: absolute; */
  background-color: var(--main-color);
  width: 100%;
  /* bottom: -5rem; */
  padding: 8px;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  box-shadow: var(--main-color) 0 0 0.5rem;
  text-align: center;
}
.meet_us_des_details_box {
  line-height: 37px;
}
.doctor_name span:first-child {
  font-weight: 700;
  font-size: 24px;
  color: var(--white-color);
}
.doctor_name span:nth-child(2) {
  color: var(--white-color);
  opacity: 0.7;
  font-size: 18px;
}
.doctor_name span:last-child {
  font-size: 22px;
  /* opacity: 0.7; */
  color: var(--white-color);
}
.meet_us_des_details_box {
  font-size: 20px;
}
.meet_mobile_mode {
  display: none;
}

@media screen and (max-width: 1777px) {
  .meet_us_des_details_des.satyajit {
    gap: 2rem;
    /* margin-top: 6rem; */
  }
}
@media screen and (max-width: 1577px) {
  .meet_us_des_details_des.satyajit {
    /* gap: 2rem; */
    margin-top: 4 rem;
  }
  .meet_us_des {
    margin-right: 2rem;
  }
}
@media screen and (max-width: 1440px) {
  .meet_us_des_details_des.satyajit {
    gap: 1rem;
    margin-top: 3rem;
  }
  .meet_us_des_details_box_img img {
    width: 20rem;
    height: 20rem;
  }
  .meet_us_list {
    min-width: 20rem;
  }
}
@media screen and (max-width: 1330px) {
  .meet_us_list {
    min-width: 17rem;
  }
}
@media screen and (max-width: 1150px) {
  .meet_us_list {
    display: none;
  }
  .meet_us_des_details {
    margin-bottom: 3rem;
  }
  .meet_us_des {
    margin-right: 0;
  }
  .meet_desktop_mode {
    display: none;
  }
  .meet_mobile_mode {
    display: block;
  }
  .meet_us_des_main_title{
    display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--white-color);
  background-color: var(--main-color);
  padding: 8px 2rem;
  box-shadow: var(--main-color) 0 0 0.5rem;
  }
  .meet_us_des_main_title span:first-child{
    font-size: 42px;
    font-weight: 700;
  }
  .meet_us_des_main_title span:last-child{
    font-size: 32px;
  }
  .meet_us_des_main_title {
    background-color: var(--white-color);
    color: var(--main-color);
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
  }
  .meet_us_des_main_title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--main-color);
    z-index: -1;
    transition: all 0.5s ease;
  }
  .meet_us_des_main_title.active {
    color: var(--white-color);
  }
  .meet_us_des_main_title.active::before {
    height: 100%;
  }
  .meet_us_des_title {
    margin-bottom: 2rem;
    font-size: 56px;
    color: var(--secondary-color);
  }
  .meet_us_des_main_title span:last-child{
    transition: all 0.5s ease;
  }
  .meet_us_des_main_title.active span:last-child{
    transform: rotate(180deg);
  }
  .meet_us_des_box {
    height: 0;
    transform: scaleY(0);
    transition: all 0.8s ease;
    overflow: hidden;
  }
  .meet_us_des_box.active {
    height: 100%;
    transform: scaleY(1);
  }
  .dis_active{
    display: none;
  }
  .meet_mobile_mode_box{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
@media screen and (max-width:991px){
  .meet_us_des{
    margin-bottom: 2rem;
  }
  .meet_us_des_main_title span:first-child{
    font-size: 32px;
  }
  .meet_us_des_main_title.active span:last-child{
    font-size: 22px;
  }
  .meet_us_des_title{
    font-size: 48px;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
  .meet_us_des_sub_title{
    font-size: 22px;
    margin: 0;
  }
  .meet_us_des_details_des{
    margin: 0;
    margin-left: 10px;
  }
  .meet_us_des_details{
    margin-top: 1rem;
  }
  .meet_us_des_details{
    flex-direction: column-reverse;
  }
  .meet_us_des_details_box_img{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}

@media screen and (max-width:767px){
  .meet_us_des_main_title span:first-child{
    font-size: 28px;
    font-weight: 600;
  }
  .meet_us_des_title{
    font-size: 36px;
    line-height: 3rem;
  }
  .meet_us_des_sub_title{
    font-size: 20px;
    font-weight: 500;
  }
  .meet_us_des_details_box_img img{
    width: 18rem;
    height: 18rem;
  }
  .doctor_name span:first-child{
    font-size: 22px;
  }
  .doctor_name span:nth-child(2){
    font-size: 16px;
  }
  .doctor_name span:last-child{
    font-size: 20px;
  }
 .meet_us_des_details_box{
  font-size: 17px;
  line-height: 33px;
 }
}