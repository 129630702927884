@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&family=Pinyon+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kurale&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100..900&display=swap");

:root {
  --main-color: #3f51b5;
  --light-color: #7b1fa237;
  --secondary-color: #7986cb;
  --white-color: #fff;
  --black-color: #000;
  --ff-normal: "Nunito", sans-serif;
  --ff-height: "Oswald", sans-serif;
  --ff-italic: "Pinyon Script", cursive;
  --ff-heading: "Kurale", serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  /* font-family: var(--ff-normal); */
}
li {
  list-style: none;
}
a.link {
  text-decoration: none;
}
.container {
  margin: 0 5rem;
}
