:root {
  --main-color: #3f51b5;
  --light-color: #7b1fa237;
  --secondary-color: #7986cb;
  --white-color: #fff;
  --black-color: #000;
  --ff-normal: "Nunito", sans-serif;
  --ff-height: "Oswald", sans-serif;
  --ff-italic: "Pinyon Script", cursive;
  --ff-heading: "Kurale", serif;
}

.bg_design {
  position: absolute;
  top: 10rem;
  left: 0;
  height: 65vh;
  width: 100%;
  background-color: #dfe2f0;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0%, 100% 81%, 50% 99%, 0 81%);
}
.service_page_details {
  height: 100%;
  /* width: 100%; */
  /* width: 90vw; */
  margin-top: -7rem;
  display: flex;
  gap: 3rem;
}
.service_page_list {
  /* flex-basis: 1; */
  width: 30%;
  background: linear-gradient(
    -90deg,
    var(--secondary-color),
    var(--main-color)
  );
  padding-left: 1rem;
  padding-top: 7rem;
  position: relative;
}
.service_page_list_main {
  position: sticky;
  top: 5.5rem;
}
.service_page_list_name {
  padding: 8px;
  color: var(--white-color);
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  text-transform: capitalize;
}
.service_page_list_name.active {
  background: var(--secondary-color);
}
.service_page_list_sub_title {
  transition: height 0.4s ease;
}
.service_page_list_sub_title.hello {
  height: 0;
  /* border: 1px solid #0092d1; */
  overflow: hidden;
}
.service_page_list_sub_title.drop_down {
  height: 500px;
}
.service_page_list_sub_title.one.drop_down {
  height: 482px;
}
.service_page_list_sub_title.two.drop_down {
  height: 486px;
}
.service_page_list_sub_title.three.drop_down {
  height: 448px;
}
.service_page_list_sub_title.four.drop_down {
  height: 664px;
}
.service_page_list_sub_title_name.two_coloum {
  column-gap: 2rem;
}
.service_page_list_sub_title_name_box {
  padding: 8px 1rem;
  font-size: 16px;
  list-style: disc;
  font-weight: 500;
  text-transform: capitalize;
  text-indent: -22px;
  padding-left: 23px;
  break-inside: avoid;
}
.service_page_list_sub_title_name_box.active {
  background-color: var(--main-color);
  color: var(--white-color);
  box-shadow: var(--white-color) 0 0 0.6rem;
  border: 1px solid var(--white-color);
  max-width: 30rem;
}
.service_page_des {
  padding-top: 9rem;
  margin-right: 2rem;
  /* flex-basis: 2; */
  width: 70%;
}
.service_page_des_content_details {
  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: column; */
  /* gap: 3rem; */
  /* justify-content: space-around; */
}
.service_page_des_content,
.service_page_details_two {
  display: block;
  transition: all 0.4s ease;
}
.service_page_details_two.mobile {
  height: 0;
  transform: scaleY(0);
  transition: all 0.8s ease;
  overflow: hidden;
}
.service_page_details_two.mobile.active {
  height: 100%;
  transform: scaleY(1);
}
.first_col {
  flex: 1;
  order: -2;
}
.second_col {
  order: 1;
}
.second_col_content {
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 1px solid rgb(199, 199, 199);
}
.second_col_name {
  font-size: 24px;
  width: 25rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 1rem 8px;
  text-align: center;
}
.second_col_content:hover {
  /* border: none; */
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 0.8rem;
  transform: scale(1.01);
}
.second_col img {
  width: 25rem;
  height: 23rem;
  border-radius: 8px;
  object-fit: cover;

  /* padding: 1rem; */
  box-shadow: rgba(0 0 0 /1%) 0 0 1rem;
  /* border-radius: 50%; */
}
.service_page_des_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white-color);
  background-color: var(--main-color);
  padding: 8px 2rem;
  box-shadow: var(--main-color) 0 0 0.5rem;
}
.service_page_des_title span:first-child {
  font-size: 48px;
  text-transform: capitalize;
}
.service_page_des_title span:last-child {
  font-size: 30px;
  transition: 0.3s;
}
.service_page_des_title span:last-child.active {
  transform: rotate(180deg);
}
.service_page_des_content_details {
  padding: 3rem;
}
.service_page_list_sub_title_name {
  background: #ffffff86;
  cursor: pointer;
}
.service_page_list_sub_head {
  padding: 8px;
  text-transform: capitalize;
  /* flex: 1; */
  /* text-align: center; */
  font-size: 20px;
  background-color: var(--white-color);
  color: var(--black-color);
  font-weight: 700;
}
.service_page_des_content_details_heading {
  font-size: 32px;
  margin: 2rem 0;
  text-transform: capitalize;
  font-weight: 700;
}
.service_page_des_content_details_des {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.service_page_des_content_sub_details {
  column-count: 2;
  margin: 2rem 0;
  column-gap: 20px;
}
.service_page_des_content_sub_details_box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.com_one_content {
  padding: 1rem;
}
.title_style {
  font-size: 18px;
  /* font-weight: 700; */
  /* text-align: center; */
  text-transform: capitalize;
}
.des_style {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.des_style_box_disc {
  list-style: disc;
  text-indent: -20px;
  padding-left: 20px;
}
.des_style_box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.sub_title_style {
  /* list-style-type: disc; */
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
}
.sub_title_style_two {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  color: var(--main-color);
}
.normal_text,
.subDes {
  font-size: 18px;
}

.service_page_des_content_details_des_details {
  font-size: 28px;
  font-weight: 700;
}
.service_page_list_sub_title_two {
  margin-bottom: 3rem;
  display: block;
  margin: 0 1rem;
}
.service_page_list_sub_title_two.width {
  width: 75%;
}
.service_page_list_sub_title_two_img img {
  width: 100%;
  transition: all 0.3s ease;
  height: 220px;
  filter: brightness(0.8);
  object-fit: cover;
}
.service_page_list_sub_title_two_img img:hover {
  filter: brightness(1);
}
.service_page_list_sub_title_two_img img.active {
  filter: brightness(0.3);
}
.service_page_list_sub_title_two_des_box {
  max-width: 22rem;
  text-align: center;
}
.to_note{
  background: rgb(220, 220, 220);
  font-weight: 600;
  padding: 8px;
  text-align: center;
  margin-bottom: 10px;
}
.service_page_list_sub_title_two_box {
  box-shadow: rgba(0 0 0 /0.2) 5px 5px 10px;
  border-radius: 5px;
  border: 1px solid rgb(230, 230, 230);
  transition: all 0.5s ease;
  overflow: hidden;
  cursor: pointer;
  height: 282px;
  position: relative;
}
.service_page_list_sub_title_two_name {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  min-height: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 1rem;
}
.service_page_list_sub_title_two_box_content {
  transform: translateY(0);
  background-color: var(--white-color);
  /* overflow: hidden; */
  max-height: 225px;
  width: 100%;
  /* overflow-y: scroll; */
  transition: all 0.3s ease;
}
.service_page_list_sub_title_two_box_content.active {
  transform: translateY(-99%);
  overflow-y: scroll;
}
.service_page_list_sub_title_two_des {
  padding: 5px 1rem;
}
.service_page_des_content_details_des_sub_details.flex {
  display: flex;
  justify-content: space-between;
}
.service_page_des_all_service_content_sub_head_name {
  font-size: 22px;
  font-weight: 700;
  margin: 1rem;
}

.service_page_list_sub_title_two .contral-btn {
  position: absolute;
  margin: auto;
  top: 35%;
  width: 100%;
  z-index: 7;
}
.service_page_list_sub_title_two .contral-btn .next,
.service_page_list_sub_title_two .contral-btn .prev {
  border: none;
  background: none;
  font-size: 20px;
  color: var(--white-color);
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  opacity: 0.6;
  border: 1px solid var(--white-color);
  font-weight: 100;
  border-radius: 50%;
  cursor: pointer;
}
.service_page_list_sub_title_two .contral-btn .next:hover,
.service_page_list_sub_title_two .contral-btn .prev:hover {
  opacity: 0.8;
  background-color: var(--main-color);
}
.service_page_list_sub_title_two .contral-btn .next {
  right: -2%;
  position: absolute;
}
.service_page_list_sub_title_two .contral-btn .prev {
  left: -2%;
  position: absolute;
}
.service_page_des_content_details_des_sub_details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service_page_des_content_details_des_sub_details_two .box {
  list-style: disc;
  text-indent: -22px;
  padding-left: 25px;
}
.single_item {
  width: 23.5%;
  margin-left: 10px;
}
.mobile_mode {
  display: none;
}
.service_page_list_sub_head{
  padding: 0;
}
.service_page_list_sub_title_name{
  padding: 12px;
}

@media screen and (max-width: 1300px) {
  .service_page_list_sub_title_two.width {
    width: 96%;
  }
  .single_item {
    width: 31.5%;
  }
}
@media screen and (max-width: 1140px) {
  .service_page_list {
    width: 35%;
  }
  .service_page_des {
    width: 65%;
  }
}

@media screen and (max-width: 991px) {
  .bg_design {
    height: 52.5vh;
  }
  .service_page_list {
    display: none;
  }
  .service_page_details {
    margin-bottom: 3rem;
  }
  .hero_banner_content.new_design {
    height: 48vh;
  }
  .service_page_des {
    width: 100%;
  }
  .desktop_mode {
    display: none;
  }
  .mobile_mode {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .hero_banner.not_homa_page {
    height: 57vh;
  }
  .service_page_des_title span:first-child {
    font-size: 32px;
  }
  .service_page_des_content_details_des_details {
    font-size: 24px;
    font-weight: 600;
  }
  .service_page_des_content_details {
    padding: 2rem 3rem;
  }
  .service_page_des_title {
    background-color: var(--white-color);
    color: var(--main-color);
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
  }
  .service_page_des_title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--main-color);
    z-index: -1;
    transition: all 0.5s ease;
  }
  .service_page_des_title.active {
    color: var(--white-color);
  }
  .service_page_des_title.active::before {
    height: 100%;
  }
  .service_page_des_content_details_des {
    gap: 2rem;
  }
  .service_page_des_content_details{
    padding: 1rem 5px;
  }
  
}

@media screen and (max-width: 767px) {
  .hero_banner_content.new_design {
    height: 42vh;
  }
  .bg_design {
    height: 44.5vh;
  }
  .service_page_des_title span:first-child {
    font-size: 28px;
  }
  .service_page_des_content_details_des_details {
    font-size: 22px;
  }
  .service_page_des_content_details_des {
    gap: 1rem;
  }
  .single_item {
    width: 49%;
  }
}
@media screen and (max-width: 590px) {
  .bg_design {
    height: 42vh;
  }
}


@media screen and (max-width: 520px) {
  .single_item {
    width: 98%;
  }
  .service_page_des_title span:first-child {
    font-size: 24px;
  }
}

